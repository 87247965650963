<template>
    <div class="registration-panel p-4 surface-brand-2 w-full grid place-items-center">
        <div class="modal-after-test-wrapper hidden">
            <ModalAfterTestPeriodView />
        </div>

        <div class="w-1/4 mx-auto surface-2 shadow-1 rounded px-8 pt-6 pb-8 mb-4" style="min-width: 416px;">

            <div class="flex items-center flex-col">
                <!--<img alt="BTSO logo" class="logo heading-4-medium-s" src="@/assets/logo-main.svg" />-->
                <BtsoLogo class="w-32" />
            </div>
            <p v-if="!route.meta.testLogin" class="heading-5-medium text-center my-6">{{
                $t('registrationView.registrationPage') }}</p>
            <p v-else class="heading-5-medium text-center my-6">{{ $t('registrationView.testLoginText') }}</p>
            <LanguageSectionWithIcon />
            <form class="pt-5" v-on:submit.prevent="register">
                <input type="hidden" v-model="form.free_trial" id="free_trial" />
                <div :class="{ 'has-error': errors.name[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="name">
                        {{ $t('registrationView.company') }}
                    </label>
                    <input v-model="form.name" @focus="reset('name')" class="input border rounded w-full" id="name"
                        type="text" :placeholder="$t('registrationView.yourCompanyName')">
                    <span class="feedback" v-if="errors.name[0]">{{ errors.name[0] }}</span>
                </div>
                <div :class="{ 'has-error': errors.email[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="email">
                        {{ $t('registrationView.email') }}
                    </label>
                    <input v-model="form.email" @focus="reset('email')" class="input border rounded w-full" id="email"
                        type="text" :placeholder="$t('registrationView.emailPlaceholder')">
                    <span class="feedback" v-if="errors.email[0]">{{ errors.email[0] }}</span>
                </div>
                <div v-if="!route.meta.testLogin" :class="{ 'has-error': errors.membership_number[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="membership_number">
                        {{ $t('registrationView.membershipNumber') }}
                    </label>
                    <input v-model="form.membership_number" @focus="reset('membership_number')"
                        class="input border rounded w-full" id="membership_number" type="text"
                        :placeholder="$t('registrationView.membershipPlaceholder')">
                    <span class="feedback" v-if="errors.membership_number[0]">{{ errors.membership_number[0] }}</span>
                </div>
                <div :class="{ 'has-error': errors.password[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="password">
                        {{ $t('registrationView.password') }}
                    </label>
                    <input v-model="form.password" @focus="reset('password')" class="input border rounded w-full"
                        id="password" type="password" :placeholder="$t('registrationView.passwordPlaceholder')">
                    <div v-if="errors.password.length > 0">
                        <span v-for="(error, index) in errors.password" :key="index" class="feedback">{{ error }}</span>
                    </div>
                </div>
                <div :class="{ 'has-error': errors.repeat_password[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="repeat_password">
                        {{ $t('registrationView.confirmPassword') }}
                    </label>
                    <input v-model="form.repeat_password" @focus="reset('repeat_password')"
                        class="input border rounded w-full" id="repeat_password" type="password"
                        :placeholder="$t('registrationView.repeatPasswordPlaceholder')">
                    <span class="feedback" v-if="errors.repeat_password[0]">{{ errors.repeat_password[0] }}</span>
                </div>
                <div :class="{ 'has-error': errors.accept_terms[0] }" class="mb-4">
                    <label class="text-p-3-bold text-1 mb-1 flex items-center">
                        <input v-model="form.accept_terms" @change="reset('accept_terms')" id="accept_terms"
                            class="mr-1" type="checkbox" name="accept_terms">


                        <span class="">{{ $t('registrationView.pre.acceptAGB') }}&nbsp;<a class="underline" :href="downloadTerms()"
                                target="_blank">{{ $t('registrationView.post.acceptAGB') }}</a></span>
                    </label>
                    <span class="feedback" v-if="errors.accept_terms[0]">{{ errors.accept_terms[0] }}</span>
                </div>
                <div class="flex items-center justify-between">
                    <button v-if="!route.meta.testLogin" class="button-primary w-full flex items-center justify-center"
                        type="submit">
                        <template v-if="isLoading">
                            <div class="loading-spinner mr-3"></div>
                            {{ $t('registrationView.button.registeringWith3Dots') }}
                        </template>
                        <template v-else>
                            {{ $t('registrationView.button.registering') }}
                        </template>

                    </button>
                    <button v-else class="button-primary w-full flex items-center justify-center" type="submit">
                        <template v-if="isLoading">
                            <div class="loading-spinner mr-3"></div>
                            {{ $t('registrationView.button.startTrialWith3Dots') }}
                        </template>
                        <template v-else>
                            {{ $t('registrationView.button.startFreeTrial') }}
                        </template>
                    </button>
                </div>

                <RouterLink class="block text-center w-full align-baseline py-2 text-caption-upper text-2 underline"
                    :to="{ name: 'login', query: { lang } }">
                    {{ $t('registrationView.areYouAlreadyMember') }}</RouterLink>

            </form>
        </div>
    </div>

    <AppModal v-show="isModalVisible" @close="closeModal" class="xl:w-3/6">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body>
            <div class="flex flex-col text-center h-96">
                <template v-if="company.plan == FREE_TRIAL_PLAN">
                    <h1 class="heading-1-bold-s pt-10">{{ $t('registrationView.thankYouFroRegistration') }}</h1>
                    <p class="w-96 mx-auto mt-7 leading-4">
                        {{ $t('registrationView.freeTrialUntil', { expired_at: company.expires_at_formatted }) }}
                    </p>
                    <div class="flex items-center justify-between mt-7">
                        <a :href="downloadTerms()" class="button-primary w-96 mx-auto" target="_blank">
                            {{ $t('registrationView.termsAndConditions') }}
                        </a>
                    </div>
                </template>
                <template v-else>
                    <h1 class="heading-1-bold-s pt-10">{{ $t('registrationView.thankYouForInquiry') }}</h1>
                    <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                        {{ $t('registrationView.yourRegistrationRequestSent') }}
                    </p>
                    <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                        {{ $t('registrationView.youReceiveConfirmationEmail') }}
                    </p>
                    <div class="flex items-center justify-between mt-7">
                        <button @click="closeModal"
                            class="button-primary w-96 mx-auto" type="submit">
                            {{ $t('registrationView.close') }}
                        </button>
                    </div>
                </template>

            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppModal>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import options from '@/options.js'
import AppModal from '@/views/components/common/AppModal.vue'
import BtsoLogo from '@/views/components/icons/BtsoLogo.vue'
import LanguageSectionWithIcon from "@/views/components/LanguageSectionWithIcon.vue";
import ModalAfterTestPeriodView from "@/views/components/common/ModalAfterTestPeriodView.vue"


const store = useStore()
const router = useRouter()
const route = useRoute()
const isLoading = computed(() => store.getters['auth/isLoading'])

const lang = route?.query?.lang || 'de';

const FREE_TRIAL_PLAN = 'free-trial'
const company = ref({})

const form = reactive({
    name: '',
    email: '',
    membership_number: '',
    password: '',
    repeat_password: '',
    accept_terms: false,
    free_trial: false,
})

let errors = reactive({
    name: [],
    email: [],
    membership_number: [],
    password: [],
    repeat_password: [],
    accept_terms: [],
    free_trial: [],
})

let isModalVisible = ref(false)

onMounted(() => {
    store.dispatch('app/showMenu', false)

    if (route.meta.testLogin) {
        form.free_trial = true
    }
})

const reset = (field) => {
    console.log(field)
    errors[field] = [];
}

const showModalAfterTest = async () => {
    const modalData = document.querySelector('.modal-after-test-wrapper')
    if (modalData) {
        modalData.classList.remove('hidden');
    }
};

const register = async () => {

    let payload = toRaw(form)

    let resp = await store.dispatch('auth/register', payload).catch((err) => {
        if (err.status === 409) showModalAfterTest();
        Object.assign(errors, err?.data?.errors)
    })

    if (resp) {
        company.value = resp.company || {}

        showModal()

    }

    //router.push({ name: 'login' })
}

const showModal = () => {
    document.body.classList.add('overflow-hidden')
    isModalVisible.value = true
}

const closeModal = () => {
    document.body.classList.remove('overflow-hidden')
    isModalVisible.value = false

    
    if (company.value.plan == FREE_TRIAL_PLAN) {
        router.push({ name: 'login', query: { lang } })
        const term_url = `${options.baseUrl}/download-terms?lang=${lang.value}`;
        window.open(term_url, '_blank');
    }
}

const onTermsClick = function () {
    closeModal()

    router.push({ name: 'login', query: { lang } })

    window.open('/terms.pdf', '_blank')
}

let downloadTerms = function () {
    return `${options.baseUrl}/download-terms?lang=${lang}`
}

/*const downloadTerms = () => {
    const url = `/download-terms`;
    window.open(url, '_blank');
}*/

</script>