<template>
    <div class="inner for-converting" ref="toimg" :class="{ 'bg-white mx-auto converting': isConverting }">
        <div class="anamnese-panel-outter xl:w-3/4">
            <div v-if="anamnese" class="panel anamnese-panel p-6 bg-white rounded w-full xl:w-9/12 mx-auto"
                :class="{ 'shadow-md': !isConverting }">

                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center pr-10">
                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                        $t('testEvaluationView.anamnese') }}</div>

                    <div
                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                        <div v-if="anamnese.status == 'suspicious'"
                            class="w-full h-7 rounded flex items-center justify-center bg-red"><span
                                :class="{ '-top-3': isConverting }">{{
                                    $t('testEvaluationView.noticeable') }}</span></div>
                        <div v-if="anamnese.status == 'ok'"
                            class="w-full h-7 rounded flex items-center justify-center bg-green"><span
                                :class="{ '-top-3': isConverting }">{{ $t('testEvaluationView.ok')
                                }}</span></div>
                        <div v-if="anamnese.status == 'skipped'"
                            class="w-full h-7 rounded flex items-center justify-center bg-neutral"><span
                                :class="{ '-top-3': isConverting }">{{ $t('testEvaluationView.skipped')
                                }}</span></div>

                    </div>
                </div>

                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                        $t('testEvaluationView.visionRelatedHeadaches') }}</div>

                    <div
                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_headaches.near, 'bg-red': anamnese.visual_headaches.near, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.vicinity') }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_headaches.pc, 'bg-red': anamnese.visual_headaches.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{ $t('testEvaluationView.pc')
                                }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_headaches.far, 'bg-red': anamnese.visual_headaches.far, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.distance') }}</span>
                        </div>
                    </div>
                </div>

                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                        $t('testEvaluationView.doubleVision') }}</div>

                    <div
                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.double_vision.near, 'bg-red': anamnese.double_vision.near, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.vicinity') }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.double_vision.pc, 'bg-red': anamnese.double_vision.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{ $t('testEvaluationView.pc')
                                }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.double_vision.far, 'bg-red': anamnese.double_vision.far, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.distance') }}</span>
                        </div>
                    </div>
                </div>

                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                        $t('testEvaluationView.visionRelatedProblems') }}</div>

                    <div
                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_problems.near, 'bg-red': anamnese.visual_problems.near, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.vicinity') }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_problems.pc, 'bg-red': anamnese.visual_problems.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{ $t('testEvaluationView.pc')
                                }}</span>
                        </div>
                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                            :class="{ 'bg-green': !anamnese.visual_problems.far, 'bg-red': anamnese.visual_problems.far, 'bg-neutral': anamnese.status == 'skipped' }">
                            <span :class="{ '-top-3': isConverting }">{{
                                $t('testEvaluationView.distance') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="panel graph-panel mt-6 p-6 bg-white rounded xl:w-3/4" :class="{ 'shadow-md': !isConverting }">
            <div>
                <canvas id="chart2" class="w-full"></canvas>
            </div>

            <canvas id="img1" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img2" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img3" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img4" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img5" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img6" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img7" style="display:none;" width="40" height="26"></canvas>
            <canvas id="img8" style="display:none;" width="40" height="26"></canvas>

        </div>
    </div>

    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 p-3 surface-2 rounded'">
            <h1 class="heading-3-medium text-1">{{ $t('testEvaluationView.evaluationOfDataProfile') }}</h1>
            <!--<button @click.prevent="exportGraphImg()">export img</button>-->

            <div class="h-1 hr-divider mb-4"></div>
            <div class="bg-white rounded px-3 pt-10 pb-5">

                <div class="bg-white flex items-center justify-center text-1 text-p-3" :class="{ 'hidden': !isLoading }"
                    :style="{ height: calculateInnerHeight() }">
                    <div class="loading-spinner mr-3"></div>
                    {{ $t('testEvaluationView.loadingWith3Dots') }}
                </div>

                <div class="outter rounded-lg px-5 py-3" :class="{ 'hidden': isLoading }">

                    <div class="inner">
                        <div class="anamnese-panel-outter xl:w-3/4">
                            <div v-if="anamnese"
                                class="panel anamnese-panel p-6 bg-white rounded w-full xl:w-9/12 mx-auto shadow-md">

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }"
                                    class="flex items-center pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                                        $t('testEvaluationView.anamnese') }}</div>

                                    <div
                                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div v-if="anamnese.status == 'suspicious'"
                                            class="w-full h-7 rounded flex items-center justify-center bg-red"><span>{{
                                                $t('testEvaluationView.noticeable') }}</span></div>
                                        <div v-if="anamnese.status == 'ok'"
                                            class="w-full h-7 rounded flex items-center justify-center bg-green">
                                            <span>{{ $t('testEvaluationView.ok') }}</span>
                                        </div>
                                        <div v-if="anamnese.status == 'skipped'"
                                            class="w-full h-7 rounded flex items-center justify-center bg-neutral">
                                            <span>{{ $t('testEvaluationView.skipped') }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }"
                                    class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                                        $t('testEvaluationView.visionRelatedHeadaches') }}</div>

                                    <div
                                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_headaches.near, 'bg-red': anamnese.visual_headaches.near, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.vicinity') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_headaches.pc, 'bg-red': anamnese.visual_headaches.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.pc') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_headaches.far, 'bg-red': anamnese.visual_headaches.far, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.distance') }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }"
                                    class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                                        $t('testEvaluationView.doubleVision') }}</div>

                                    <div
                                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.double_vision.near, 'bg-red': anamnese.double_vision.near, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.vicinity') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.double_vision.pc, 'bg-red': anamnese.double_vision.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.pc') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.double_vision.far, 'bg-red': anamnese.double_vision.far, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.distance') }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }"
                                    class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">{{
                                        $t('testEvaluationView.visionRelatedProblems') }}</div>

                                    <div
                                        class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_problems.near, 'bg-red': anamnese.visual_problems.near, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.vicinity') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_problems.pc, 'bg-red': anamnese.visual_problems.pc, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.pc') }}</span>
                                        </div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center"
                                            :class="{ 'bg-green': !anamnese.visual_problems.far, 'bg-red': anamnese.visual_problems.far, 'bg-neutral': anamnese.status == 'skipped' }">
                                            <span>{{ $t('testEvaluationView.distance') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="panel graph-panel mt-6 p-6 bg-white rounded xl:w-3/4 shadow-md">
                            <div>
                                <canvas id="chart" class="w-full"></canvas>
                            </div>

                            <canvas id="img1" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img2" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img3" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img4" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img5" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img6" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img7" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img8" style="display:none;" width="40" height="26"></canvas>
                        </div>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <p class="subheading-bold mb-3">{{ $t('testEvaluationView.overviewOfAllClasses') }}</p>
                        <div v-if="treatment && treatment.system_notes">{{ treatment.system_notes }}</div>
                    </div>

                    <div v-if="documents.length" class="documentation-info-panel xl:w-3/4 mt-4 px-3 py-10 --text-white">
                        <div class="documentation-inner max-h-96 overflow-y-auto px-3">
                            <ul class="--text-white max-h-96 overflow-y-auto pr-1">
                                <li v-for="document in documents"
                                    class="flex items-center flex-col pb-6 w-full border-b border-gray-200 mb-6">
                                    <div class="w-full mt-4 px-3">
                                        <object :data="document.url" type="application/pdf" width="100%" height="450px">
                                            <p>Alternative text - include a link <a :href="document.url">to the PDF!</a>
                                            </p>
                                        </object>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <p class="subheading-bold mb-3">{{ $t('testEvaluationView.inputValues') }}</p>
                    </div>

                    <div>
                        <table class="tests-table" width="100%" cellpadding="6" cellspacing="0">
                            <thead>
                                <tr>
                                    <td style="text-align: left;" scope="col"><b>{{ $t('testEvaluationView.sequence')
                                            }}</b></td>
                                    <td style="text-align: left;" scope="col"><b>{{
                                        $t('testEvaluationView.concretization') }}</b></td>
                                    <td style="text-align: right;" scope="col"><b>{{ $t('testEvaluationView.values')
                                            }}</b></td>
                                    <td style="text-align: right;" scope="col"><b>{{ $t('testEvaluationView.unit')
                                            }}</b></td>
                                    <td style="text-align: center;" scope="col"><b>{{
                                        $t('testEvaluationView.additional') }}</b></td>
                                </tr>
                            </thead>
                            <tbody>

                                <template v-for="test in testData">
                                    <tr v-for="property in test.properties">
                                        <td>
                                            <b>{{ test.test }}</b>
                                            <template v-if="property.name !== 'Default' && property.name !== ''">
                                                <br>{{ property.name }}
                                            </template>
                                        </td>
                                        <td style="padding: 6px;" v-if="test.method && test.method !== ''">
                                            {{ test.method }}
                                        </td>
                                        <td style="padding: 6px;" v-else></td>
                                        <td style="width: 100px; text-align: right;">{{ property.value }}</td>
                                        <td style="width: 100px; text-align: right;">{{ property.unit }}</td>
                                        <td></td>
                                    </tr>
                                </template>

                            </tbody>
                        </table>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <textarea v-model="notes" class="bg-transparent w-full h-32"
                            :placeholder="$t('testEvaluationView.enterCommentsHere')"></textarea>

                        <div :class="{ 'animate__animated animate__headShake': unsuccessful }">
                            <label class="cursor-pointer"><input v-model="acceptTerms" type="checkbox"
                                    class="cursor-pointer"><span class="ml-2">{{ $t('testEvaluationView.iAcceptTheAGBs')
                                    }}</span></label>
                        </div>
                    </div>

                </div>

                <button v-if="!isConverting" @click="finalizeTreatment()"
                    class="notes-save-btn button-primary w-full mt-4 px-5 py-2 radius-sm uppercase">
                    {{ $t('testEvaluationView.completeSequence') }}</button>

            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router'
import options from '@/options.js'

import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

import axios from 'axios'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

//import { Chart } from 'chart.js'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { set } from 'lodash';

Chart.defaults.font.family = "Overpass"
Chart.defaults.font.weight = "600"
Chart.defaults.font.size = "14px"

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n();

const lang = route?.query?.lang || 'de';


const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

let treatment = computed(() => store.getters['treatments/treatment'].data)
let graph = computed(() => store.getters['tests/graph'])
let anamnese = computed(() => store.getters['tests/anamnese'])
let documents = computed(() => store.getters['documents/all'])
let testData = computed(() => store.getters['tests/testData'])

let toimg = ref()

let isLoading = ref(true)
let isLoading2 = ref(true)
let isConverting = ref(true)

let notes = ref('')
let acceptTerms = ref(false)

let unsuccessful = ref(false)

const points = []
const graphBlob = ref(null);
const isMounted = ref(false); // Variabilă pentru a verifica dacă onMounted a fost apelat

const plugin = {
    id: 'custom_canvas_background',
    beforeDraw: (chart) => {

        let ctx = chart.canvas.getContext('2d')
        ctx.save()
        ctx.globalCompositeOperation = 'destination-over'

        const chartArea = chart.chartArea
        let offset = chartArea.left

        //area 1
        let aWidth = Math.ceil(35.58 * chartArea.width / 100) + 1
        let gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        //version 3
        let outerColor1 = '#dba8a4'
        let outerColor2 = '#ffe4d4'
        let innerColor1 = '#e8f4dc'
        let innerColor2 = '#c8e4b4'

        //first section: 7.10%, next three sections: 42.73%, next three: 42.73%, last section: 7.10%
        gradientBack.addColorStop(0, outerColor1)
        gradientBack.addColorStop(1, outerColor2)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 2
        aWidth = Math.ceil(14.24 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0, innerColor1)
        gradientBack.addColorStop(0.5, innerColor2)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 3
        aWidth = Math.ceil(14.24 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0.5, innerColor2)
        gradientBack.addColorStop(1, innerColor1)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 4
        aWidth = Math.ceil(35.58 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0, outerColor2)
        gradientBack.addColorStop(1, outerColor1)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        ctx.restore()
    }

}

let finalizeTreatment = async function () {

    if (acceptTerms.value) {
        let treatment = await store.dispatch(`treatments/finalizeTreatment`, {
            patientId,
            treatmentId,
            data: {
                finished: 1,
                notes: notes.value,
                accept_terms: acceptTerms.value
            }
        }).catch((error) => { })

        window.open(getEvaluationDownloadLink(treatmentId), '_blank')

        if (treatment) {
            router.push({
                name: 'patients',
                query: {
                    lang
                }
            })
        }

    } else {

        unsuccessful.value = true

        setTimeout(() => {
            unsuccessful.value = false
        }, 1200, true)
    }
}
let getEvaluationDownloadLink = function (treatmentId) {
    return `${options.baseUrl}/patients/${patientId}/treatments/${treatmentId}/evaluation-download`
}

let calculateInnerHeight = function () {
    return window.innerHeight + 'px'
}

const captureGraphImg = async () => {
    try {
        const canvas = await html2canvas(toimg.value);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', 0.95)); // Schimbăm formatul în 'image/jpeg' și setăm calitatea la 0.95
        graphBlob.value = blob;
        return blob;
    } catch (error) {
        console.error('Error capturing graph image:', error);
        throw error;
    }
};

const uploadGraphImg = (blob) => {
    let fd = new FormData();
    fd.append('fname', 'graph.jpg'); // Schimbăm extensia fișierului în 'graph.jpg'
    fd.append('evaluation_image', blob);

    return axios({
        method: 'post',
        url: `api/patients/${patientId}/treatments/${treatmentId}/upload-evaluation-image`,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data;',
        },
    });
};

const createChart = (ctx, data) => {
    return new Chart(ctx, {
        type: 'line',
        data: {
            labels: graph.value.labels,
            datasets: [{
                axis: 'y',
                label: t('testEvaluationViewChart.Behandlugsverlauf'),
                data: data,
                fill: false,
                borderColor: '#003A56',
                borderWidth: 1,
                pointRadius: 0,
            }]
        },
        options: {
            responsive: true,
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    display: true,
                    offset: true,
                    min: -3,
                    max: 3,
                    position: 'top',
                    ticks: {
                        font: {
                            family: 'Inter',
                        }
                    }
                },
                y: {
                    offset: true,
                    afterFit: function (scaleInstance) {
                        scaleInstance.width = 260; // sets the left labels width to 260px
                    },
                    ticks: {
                        font: {
                            family: 'Inter',
                        },
                        callback: function (value) {
                            // Împarte eticheta în linii multiple dacă este prea lungă
                            const maxLabelLength = 30; // Lungimea maximă a unei linii
                            const label = this.getLabelForValue(value);
                            if (label.length > maxLabelLength) {
                                const words = label.split(' ');
                                let lines = [];
                                let currentLine = '';
                                words.forEach(word => {
                                    if ((currentLine + word).length > maxLabelLength) {
                                        lines.push(currentLine);
                                        currentLine = word;
                                    } else {
                                        currentLine += (currentLine ? ' ' : '') + word;
                                    }
                                });
                                lines.push(currentLine);
                                return lines;
                            }
                            return label;
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    backgroundColor: function (context) {
                        return graph.value.bgcolors[context.dataIndex];
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold',
                        family: 'Inter',
                    },
                    padding: 6,
                    formatter: function (value, context) {
                        return graph.value.measured[context.dataIndex];
                    }
                },
                title: {
                    display: true,
                    text: t('testEvaluationViewChart.Standardabweichungen'),
                    fullSize: false,
                    font: {
                        weight: 'normal',
                        family: 'Inter',
                    }
                },
            },
            animation: {
                duration: 0
            },
        },
        plugins: [plugin, ChartDataLabels],
    });
}

onMounted(async () => {
    window.scrollTo({ top: 0 });

    const graphPromise = store.dispatch('tests/graph', { patientId, treatmentId, lang });

    await graphPromise.then(() => {
        const ctx2 = document.getElementById('chart2');
        createChart(ctx2, graph.value.data);
    });

    const chartPromise = graphPromise.then(() => {
        const ctx = document.getElementById('chart');
        createChart(ctx, graph.value.data);
    });



    // Fetch treatment and documents data in parallel
    const treatmentPromise = store.dispatch('treatments/treatment', { patientId, treatmentId, lang });
    const documentsPromise = store.dispatch('documents/all', { treatmentId, lang });

    // Wait for all parallel tasks to complete
    await Promise.all([chartPromise, treatmentPromise, documentsPromise]);

    // Set notes and loading state
    notes.value = treatment.value.notes;
    isLoading.value = false;

    // Set isLoading2 to false after all promises are resolved
    isLoading2.value = false;


    try {
        const blob = await captureGraphImg();
        console.log(blob);
        await uploadGraphImg(blob);
        isConverting.value = false; // Setăm isConverting la false după ce imaginea a fost încărcată
    } catch (error) {
        console.error(error);
    }
});


</script>
<style scoped>
canvas {
    max-width: 100% !important;
}

.outter {
    /*background: #f0faff;*/
    background: var(--color-shade-3);
}

.bg-green {
    background: var(--color-success-400);
}

.bg-green-light {
    background: var(--color-success-300);
}

.bg-green-lighter {
    background: var(--color-success-100);
}

.bg-red {
    background: var(--color-error-300);
}

.bg-red-light {
    background: var(--color-error-200);
}

.bg-neutral {
    background: var(--color-neutral-100);
}

.distance-graph-panel {
    border: 2px solid var(--color-shade-1);
}

.points {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
    background: var(--color-primary-50);
    border: 1px solid var(--color-primary-700);

}

.indicator {
    background: var(--color-error-600);
}

.converting {
    max-width: 1180px;
    width: 1180px;
    margin: 0 auto;
    padding: 0;
}

.graph-title {
    color: #777;
}

@media (max-width: 1366px) {

    .anamnese-panel-outter,
    .graph-panel-outter {
        width: 100% !important;
        max-width: 100% !important;
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: var(--color-shade-0);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--color-shade-1) !important;
    border-radius: 20px;
    border: 0px;
}

.tests-table tr td {
    padding: 6px;
    border: 2px solid #5a5a5a;
}

.inner.for-converting {
    position: absolute;
    left: 0;
    top: -99999px;
}
</style>