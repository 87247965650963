<template>
  <div class="flex items-center justify-items-start border-b-2 pb-2 border-black">
    <button
        @click="onClick('de')"
        :class="{ 'pr-1 cursor-pointer w-6 text-md uppercase hover:font-bold': true,
         'font-bold': currentLanguage === 'de' }">De</button>
    <button
        @click="onClick('en')"
        :class="{ 'px-1 cursor-pointer w-6 text-md uppercase hover:font-bold': true,
         'font-bold': currentLanguage === 'en' }">En</button>
    <button
        @click="onClick('it')"
        :class="{ 'px-1 cursor-pointer w-6 text-md uppercase hover:font-bold': true,
         'font-bold': currentLanguage === 'it' }">It</button>
    <button
        @click="onClick('fr')"
        :class="{ 'px-1 cursor-pointer w-6 text-md uppercase hover:font-bold': true,
         'font-bold': currentLanguage === 'fr' }">Fr</button>
   
  </div>
</template>

<script setup>
const currentLanguage = window.localStorage.getItem('lang');
const onClick = async (lang) => {
  if (currentLanguage !== lang) {
    window.localStorage.setItem('lang', lang);
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    window.history.pushState(null, '', url.toString());
    window.location.reload(true);
  }
}
</script>